import Filmes from "app_fragmentos/Filmes/Filmes";
import MenuLateral from "app_componentes/MenuLateral/MenuLateral";
import ContainerDuplo from "app_fragmentos/ContainerDuplo/ContainerDuplo";
import MenuFooter from "app_fragmentos/MenuFooter/MenuFooter";
import Inicio from "app_pages/Inicio";

function PageFilmes() {
    return (
        <Inicio >
            <ContainerDuplo
                childrenE={<MenuFooter><MenuLateral /></MenuFooter>}
                childrenC={<Filmes />} />
        </Inicio>
    );
}

export default PageFilmes;