import MenuLateral from "app_componentes/MenuLateral/MenuLateral";
import ContainerDuplo from "app_fragmentos/ContainerDuplo/ContainerDuplo";
import MenuFooter from "app_fragmentos/MenuFooter/MenuFooter";
import Inicio from "app_pages/Inicio";

function Ajuda() {

    return (
        <Inicio>
            <ContainerDuplo childrenE={<MenuFooter><MenuLateral /></MenuFooter>}
                childrenC={
                    <div>
                        <h2>Ajuda</h2>
                    </div>
                } />
        </Inicio>
    );
}

export default Ajuda;