import "./Feed.css";
import CadastroPost from "app_componentes/CadastroPost/CadastroPost";
import CardFeed from "app_componentes/CardFeed/CardFeed";
import React, { useEffect, useRef, useState } from 'react';
import { Link } from "react-router-dom";
import { sendData } from "app_utils/sendData";
import { timeSince } from "app_utils/timeSince";

function Feed({ user, isAuthenticated }) {
    const feedRef = useRef(null);
    const [posts, setPosts] = useState([]);

    const handleScroll = (event) => {
        if (feedRef.current) {
            feedRef.current.scrollTop += event.deltaY;
        }
    };

    useEffect(() => {
        const feedCallback = (error, data) => {
            console.log(data);
            if (!error && Array.isArray(data.response_received)) {
                setPosts(data.response_received);
            }
        };

        sendData('feed', new FormData(), feedCallback, true, 'POST', true);
    }, []);

    return (
        <div className="feed"
            ref={feedRef}
            onWheel={handleScroll}>
            {isAuthenticated ? (<CadastroPost user={user} setPosts={setPosts}/>) : (<Link to="/login">Você precisa estar logado para postar suas ideias</Link>)}
            {Array.isArray(posts) ? (posts.map((post) => (
                <CardFeed
                    key={post.post_id}
                    avatar={post.user_avatar}
                    username={post.user_nickname}
                    postTime={timeSince(post.message_created_at)}
                    message={post.content}
                    postImage={post.post_image}
                    video={post.video}
                    answers={post.answers}
                    userId={post.user_id}
                />
            ))) : ""}
        </div>
    );
}

export default Feed