import { Sidebar, Content } from 'rsuite';

function ContainerTriplo({ childrenE, childrenC, childrenD }) {
    return (
        <>
            <Sidebar className='AppMenuLateral'>
                {childrenE}
            </Sidebar>
            <Content className='custom-content'>
                {childrenC}
            </Content>
            <Sidebar>
                <div className='fixed z-index-1'>
                    {childrenD}
                </div>
            </Sidebar>
        </>
    );
}

export default ContainerTriplo;