import React, { useState, useRef } from 'react';
import Cropper from 'react-cropper';
import Modal from 'react-modal';
import 'cropperjs/dist/cropper.css';
import { sendData } from 'app_utils/sendData';
import { useNavigate } from 'react-router-dom';

Modal.setAppElement('#root'); // Define the root element for accessibility

const ImageCropper = () => {
  const navigate = useNavigate();
  const [image, setImage] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const cropperRef = useRef(null);

  const cortarEnviar = async () => {
    if (!croppedImage) return;

    // Convert base64 image to blob
    const response = await fetch(croppedImage);
    const blob = await response.blob();

    // Create a FormData object
    var formData = new FormData();
    formData.append('avatar', blob, 'croppedImage.png');

    const imageCallback = (error, data) => {
      if (!error && data) {
        alert('Imagem Enviada com sucesso');
        closeModal();
        navigate("/feed");
      }
    }

    sendData("image", formData, imageCallback, true, "POST", false);
  }

  const onChange = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(files[0]);
  };

  const cropImage = () => {
    if (typeof cropperRef.current.cropper !== 'undefined') {
      const croppedCanvas = cropperRef.current.cropper.getCroppedCanvas({
        width: 512,
        height: 512,
      });
      setCroppedImage(croppedCanvas.toDataURL());
      setModalIsOpen(true);
    }
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div>
      <input type="file" accept="image/*" onChange={onChange} />
      <div style={{ width: '50%' }}>
        {image && (
          <Cropper
            src={image}
            style={{ height: 500, width: '100%' }}
            initialAspectRatio={1}
            aspectRatio={1}
            preview=".img-preview"
            guides={false}
            minCropBoxHeight={256}
            minCropBoxWidth={256}
            crop={() => {
              const cropper = cropperRef.current.cropper;
              const { width, height } = cropper.getCropBoxData();

              if (width < 256 || height < 256) {
                cropper.setCropBoxData({
                  width: Math.max(width, 256),
                  height: Math.max(height, 256),
                });
              }

              if (width > 2048 || height > 2048) {
                cropper.setCropBoxData({
                  width: Math.min(width, 2048),
                  height: Math.min(height, 2048),
                });
              }
            }}
            background={false}
            responsive={true}
            autoCropArea={1}
            checkOrientation={false}
            ref={cropperRef}
          />
        )}
      </div>
      <button onClick={cropImage}>Crop Image</button>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Cropped Image Preview"
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '700px', // Set your desired width
            height: '700px', // Set your desired height
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          },
        }}
      >
        <div style={{ position: 'relative', width: '100%', height: '100%' }}>
          <button
            onClick={closeModal}
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              background: 'red',
              color: 'white',
              border: 'none',
              borderRadius: '50%',
              width: '30px',
              height: '30px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
            }}
          >
            X
          </button>
          <h2>Cropped Image</h2>
          {croppedImage && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'top',
                width: '100%',
                height: '80%',
              }}
            >
              <img
                src={croppedImage}
                alt="Cropped"
                style={{
                  maxWidth: '100%',
                  maxHeight: '100%',
                  borderRadius: '50%', // Make the image a circle
                }}
              />
            </div>
          )}
        </div>
        <div>
          <button onClick={ cortarEnviar }>Cortar e enviar</button>
        </div>
      </Modal>
    </div>
  );
};

export default ImageCropper;
