import AppHeader from "app_componentes/AppHeader/AppHeader";
import CadastroPost from "app_componentes/CadastroPost/CadastroPost";
import Footer from "app_componentes/Footer/Footer";

function Postagem () {
    const user = JSON.parse(localStorage.getItem('user'));

    return (
        <div className="App">
            <AppHeader />
            <CadastroPost user={user} />
            <Footer />
        </div>
    );
}

export default Postagem;