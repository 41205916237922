import { Sidebar, Content } from 'rsuite';

function ContainerDuplo({ childrenE, childrenC }) {
    return (
        <>
            <Sidebar className='AppMenuLateral'>
                {childrenE}
            </Sidebar>
            <Content className='custom-content'>
                {childrenC}
            </Content>
        </>
    );
}

export default ContainerDuplo;