import React, { useState } from 'react';
import "./CardFilme.css";

const CardFilme = ({ coverImage, translatedName, originalName, synopsis, releaseYear, adult }) => {

  return (
    <div className="movie-card">
      <img src={coverImage} alt={`${translatedName} cover`} className="movie-card__image" />
      <div className="movie-card__details">
        <h2 className="movie-card__title">{translatedName}</h2>
        <h3 className="movie-card__original-title">{originalName}</h3>
        <p className="movie-card__year">Ano de Lançamento: {adult ? "+" : "-"}{releaseYear}</p>
      </div>
    </div>
  );
};

export default CardFilme;