import MenuLateral from "app_componentes/MenuLateral/MenuLateral";
import ContainerDuplo from "app_fragmentos/ContainerDuplo/ContainerDuplo";
import MenuFooter from "app_fragmentos/MenuFooter/MenuFooter";
import Inicio from "app_pages/Inicio";

const Desenvolvedores = () => {
    return (
        <Inicio>
            <ContainerDuplo
                childrenE={
                    <MenuFooter><MenuLateral /></MenuFooter>
                }
                childrenC={
                    <div>
                        <h2>Desenvolvedores</h2>
                    </div>
                } />
        </Inicio>
    );
}

export default Desenvolvedores;