import LoginForm from "app_componentes/LoginForm/LoginForm";
import logo from "resources/img/logo-cineine.png";
import "./login.css";
import Footer from "app_componentes/Footer/Footer";
import { Content } from "rsuite";

function Login() {
  return (
    <>
      <Content className="AppContent border">
        <div className="Login-div justify-Center">
          <LoginForm />
        </div>
        <div className="Login-logo-div justify-Center border">
          <div>
            <img src={logo} className="Login-app-logo" alt="logo" />
          </div>
          <div>
            <strong>Conecte com seus amigos e troque informações de filmes e séries.</strong>
          </div>
        </div>
      </Content>

    </>
  );
}

export default Login