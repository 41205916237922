import './YouTubeVideo.css';
import React, { useEffect } from 'react';

const YouTubeVideo = ({ videoId }) => {
    return (
        <div className="video-responsive">
            <iframe
                width="560"
                height="315"
                src={`https://www.youtube.com/embed/${videoId}`}
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="YouTube video player"
            ></iframe>
        </div>
    );
};

export default YouTubeVideo;
