import CadastroForm from "app_componentes/CadastroForm/CadastroForm";
import { Content } from "rsuite";

function CriarConta() {
    return (
        <Content>
            <CadastroForm />
        </Content>
    );
}

export default CriarConta;