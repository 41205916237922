import { useLogout } from "app_utils/useLogout";
import { Dropdown } from "rsuite";
import "rsuite/Dropdown/styles/index.css";
import "./DropdownMenu.css"
import { useNavigate } from "react-router-dom";

function DropdownMenu({ user }) {
  const logout = useLogout();
  const navigate = useNavigate();
  const renderToggle = props => (
    <img
    src={ user.avatar }
    alt="Avatar"
    style={{ width: '50px', height: '50px', borderRadius: '50%', objectFit: 'cover' }}
    {...props}
  />
);

  const handleLogout = () => {
    logout();
    navigate('/login')
  }
  const handleProfile = () => {
    navigate(`/profile/${user.id}`)
  }

  return (
    <Dropdown renderToggle={renderToggle} placement="bottomEnd" trigger={['click']} className="custom-dropdown">
      <Dropdown.Item panel style={{ paddingLeft: 10, paddingRight: 10, width: 256 }}>
        <p>
          <strong>{user.name} {user.lastname}</strong>
        </p>
      </Dropdown.Item>
      <Dropdown.Separator />
      <Dropdown.Item><div onClick={handleProfile}>Seu Perfil</div></Dropdown.Item>
      <Dropdown.Separator />
      <Dropdown.Item><div onClick={handleLogout}>Sair</div></Dropdown.Item>
    </Dropdown>
  );
}
export default DropdownMenu;