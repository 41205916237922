import React from 'react';
import './Botao.css';

function Botao ({ id, type, onClick, children, disabled }) {
    return (
        <button id={id} type={type} onClick={onClick} disabled={disabled} >
            {children}
        </button>
    );
};

export default Botao