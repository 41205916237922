import AuthCheck from 'app_componentes/AuthCheck/AuthCheck';
import React from 'react';
import { Route, Navigate } from 'react-router-dom';

const withAuthCheck = (Component) => {
  return props => {
    return <AuthCheck><Component {...props} /></AuthCheck>;
  };
};

export default withAuthCheck;