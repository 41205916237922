import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { sendData } from 'app_utils/sendData';
import { Button, Input, Modal, Form, Checkbox, CheckboxGroup } from 'rsuite';
import CardFilme from 'app_componentes/CardFilme/CardFilme';
import './CatalogoDetails.css';

const CatalogDetails = () => {
  const { catalogId } = useParams();
  const [catalog, setCatalog] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [friendEmail, setFriendEmail] = useState('');
  const [showAddFriendModal, setShowAddFriendModal] = useState(false);
  const [friends, setFriends] = useState([]);
  const [selectedFriends, setSelectedFriends] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCatalogDetails = () => {
      let formData = new FormData();
      formData.append('action', 'get');
      formData.append('catalog_id', catalogId);

      sendData('catalog', formData, (error, data) => {
        if (!error && data.response_received) {
          setCatalog(data.response_received);
        } else {
          setError('Failed to fetch catalog details.');
        }
        setLoading(false);
      }, true, 'POST', true);
    };

    fetchCatalogDetails();
    fetchFriendsList();
  }, [catalogId]);

  const fetchFriendsList = () => {
    let formData = new FormData();
    formData.append('action', 'get');

    sendData('friends', formData, (error, data) => {
      if (!error && Array.isArray(data.response_received)) {
        const approvedFriends = data.response_received.filter(friend => friend.approved);
        setFriends(approvedFriends);
      }
    }, true, 'POST');
  };

  const handleAddFriend = () => {
    let formData = new FormData();
    formData.append('action', 'share_catalog');
    formData.append('catalog_id', catalogId);
    formData.append('friend_email', friendEmail);

    selectedFriends.forEach(friendId => {
      formData.append('friend_ids[]', friendId);
    });

    sendData('catalog', formData, (error, data) => {
      if (!error && data.response_received.success) {
        console.log('Catalog shared successfully');
      } else {
        console.error('Failed to share catalog');
      }
      setShowAddFriendModal(false);
    }, true, 'POST', true);
  };

  const handleShowModal = () => {
    console.log("Showing modal...");
    setShowAddFriendModal(true);
  };

  const handleCloseModal = () => {
    console.log("Closing modal...");
    setShowAddFriendModal(false);
  };

  if (error) {
    return <div>{error}</div>;
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="catalog-details">
      <h2>{catalog.catalog_name}</h2>
      <div className="catalog-info">
        <img src={catalog.avatar} alt={catalog.nickname} className="catalog-avatar" />
        <div className="catalog-description">
          <p>{catalog.catalog_description}</p>
          <p>Total de filmes: {catalog.total_movies}</p>
          <Button onClick={() => navigate('/films')}>Adicionar Filme</Button>
          <Button onClick={handleShowModal}>Compartilhar com Amigo</Button>
        </div>
      </div>
      <div className="movies-list">
        {catalog.movies.map(movieJson => {
          const movie = JSON.parse(movieJson);
          return (
            <CardFilme
              key={movie.id}
              coverImage={`https://image.tmdb.org/t/p/w200${movie.poster_path}`}
              translatedName={movie.title}
              originalName={movie.original_title}
              synopsis={movie.overview}
              releaseYear={movie.release_date.split('-')[0]}
              adult={movie.adult}
            />
          );
        })}
      </div>
      <Modal show={showAddFriendModal} onHide={handleCloseModal}>
        <Modal.Header>
          <Modal.Title>Compartilhar Catálogo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="friendEmail">
              <Form.ControlLabel>Email do Amigo</Form.ControlLabel>
              <Input value={friendEmail} onChange={setFriendEmail} />
            </Form.Group>
            <Form.Group controlId="friendList">
              <Form.ControlLabel>Selecionar Amigos</Form.ControlLabel>
              <CheckboxGroup 
                name="friends" 
                value={selectedFriends}
                onChange={setSelectedFriends}
              >
                {friends.map(friend => (
                  <Checkbox key={friend.id} value={friend.id}>
                    {friend.name}
                  </Checkbox>
                ))}
              </CheckboxGroup>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleAddFriend} appearance="primary">Compartilhar</Button>
          <Button onClick={handleCloseModal} appearance="subtle">Cancelar</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CatalogDetails;