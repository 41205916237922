import Botao from "app_componentes/Botao/Botao";
import "./ComentariosPost.css";
import { sendData } from "app_utils/sendData";

function ComentarioPost() {
    const user = JSON.parse(localStorage.getItem('user'));
    
    const handleCommentClick = () => {
        let formData = new FormData();
        formData.append('action')
        sendData('post_answer', new FormData)
    }
    return (
        <div className="comentario-post">
            <div>
                <img src={user.avatar} alt="Avatar do usuário" className="social-feed-card__avatar" />
            </div>
            <div className="div-campo-texto">
                <input
                    type="text"
                    placeholder="O que você está pensando?"
                />
            </div>
            <div>
                <Botao>Comentar</Botao>
            </div>
        </div>
    );
}

export default ComentarioPost;