import AppHeader from 'app_componentes/AppHeader/AppHeader';
import Profile from 'app_componentes/CorteImagem/ImageCropper';
import Footer from 'app_componentes/Footer/Footer';

function CorteImagem() {
    return (
        <div className="App">
            <AppHeader />
            <div>
                <h2>Selecione uma imagem para seu perfil</h2>
                <Profile />
            </div>
            <Footer />
        </div>
    );
}

export default CorteImagem;