import React from 'react';
import './CardCatalogo.css';

const CatalogCard = ({ catalog, onOpenCatalog }) => {
  const firstMovie = catalog.movies ? Object.values(catalog.movies)[0] : null;
  const coverImage = firstMovie ? `https://image.tmdb.org/t/p/w200${firstMovie.poster_path}` : '';

  return (
    <div className="catalog-card" onClick={onOpenCatalog}>
      <div className="catalog-cover">
        {coverImage ? (
          <img src={coverImage} alt={`${catalog.catalog_name} cover`} className="catalog-cover-image" />
        ) : (
          <div className="catalog-cover-placeholder"></div>
        )}
      </div>
      <div className="catalog-body">
        <h3>{catalog.catalog_name}</h3>
        <p>{catalog.catalog_description}</p>
        <p>Total de filmes: {catalog.total_movies}</p>
      </div>
    </div>
  );
};

export default CatalogCard;