import Botao from "app_componentes/Botao/Botao";
import React, { useState, useEffect } from 'react';
import './CadastroForm.css';
import { validaEmail } from "app_utils/validaEmail";
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'app_componentes/AuthContext/AuthContext';
import { sendData } from 'app_utils/sendData';

function CadastroForm() {
    const navigate = useNavigate();
    const { setIsAuthenticated, setUser } = useAuth();
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [lastname, setLastname] = useState('');
    const [nickname, setNickname] = useState('');
    const [repeatpassword, setRepeatPassword] = useState('');
    const [password, setPassword] = useState('');
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [touched, setTouched] = useState({});
    const [globalError, setGlobalError] = useState('');

    useEffect(() => {
        validaForm();
    }, [email, password, repeatpassword, name, lastname]);

    const validaForm = () => {
        const newErrors = {};
        if (touched.email && !validaEmail(email)) {
            newErrors.email = "Email inválido.";
        }
        if (touched.password && password.length < 6) {
            newErrors.password = "A senha deve ter pelo menos 6 caracteres.";
        }
        if (touched.repeatpassword && password !== repeatpassword) {
            newErrors.repeatpassword = "As senhas não correspondem.";
        }
        if (touched.name && !name) {
            newErrors.name = "O nome é obrigatório.";
        }
        if (touched.lastname && !lastname) {
            newErrors.lastname = "O sobrenome é obrigatório.";
        }
        if (touched.nickname && !nickname) {
            newErrors.nickname = "O sobrenome é obrigatório.";
        }

        setErrors(newErrors);
        setIsButtonDisabled(Object.keys(newErrors).length > 0 || !email || !password || !repeatpassword || !name || !lastname);
    };

    const handleBlur = (field) => () => {
        setTouched({ ...touched, [field]: true });
        validaForm();
    };

    const callback = (error, data) => {
        setIsLoading(false);
        console.log(error);
        console.log(data);
        if (!error && data.response_received) {
            setUser(data.user);
            setIsAuthenticated(true);
            localStorage.setItem('authenticated');
            localStorage.setItem('user', JSON.stringify(data.user));
            navigate('/feed');
        } else {
            setGlobalError('Erro ao criar conta. Por favor, tente novamente.');
        }
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        const formData = new FormData();
        formData.append('action', 'new')
        formData.append('name', name);
        formData.append('lastname', lastname);
        formData.append('nickname', nickname);
        formData.append('email', email);
        formData.append('password', password);

        sendData("profile_user", formData, callback, true, "POST", false);
    };

    return (
        <form className="cadastro" onSubmit={handleSubmit}>
            <h2>Criar uma nova conta</h2>
            {globalError && <p className="error-global">{globalError}</p>}
            <div>
                <input
                    type="text"
                    placeholder="Nome"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    onBlur={handleBlur('name')}
                    name="name"
                    required
                />
                {errors.name && <p className="error">{errors.name}</p>}
            </div>
            <div>
                <input
                    type="text"
                    placeholder="Sobrenome"
                    value={lastname}
                    onChange={(e) => setLastname(e.target.value)}
                    onBlur={handleBlur('lastname')}
                    name="lastname"
                    required
                />
                {errors.surname && <p className="error">{errors.surname}</p>}
            </div>
            <div>
                <input
                    type="text"
                    placeholder="Nickname"
                    value={nickname}
                    onChange={(e) => setNickname(e.target.value)}
                    onBlur={handleBlur('nickname')}
                    name="nickname"
                    required
                />
                {errors.surname && <p className="error">{errors.surname}</p>}
            </div>
            <div>
                <input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    onBlur={handleBlur('email')}
                    required
                />
                {errors.email && <p className="error">{errors.email}</p>}
            </div>
            <div>
                <input
                    type="password"
                    placeholder="Digite sua senha"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onBlur={handleBlur('password')}
                    required
                />
                {errors.password && <p className="error">{errors.password}</p>}
            </div>
            <div>
                <input
                    type="password"
                    placeholder="Repita a Senha"
                    value={repeatpassword}
                    onChange={(e) => setRepeatPassword(e.target.value)}
                    onBlur={handleBlur('repeatpassword')}
                    required
                />
                {errors.repeatpassword && <p className="error">{errors.repeatpassword}</p>}
            </div>
            <Botao type="submit" disabled={isButtonDisabled || isLoading}>
                {isLoading ? 'Carregando...' : 'Criar conta'}
            </Botao>
        </form>
    );
}

export default CadastroForm;
