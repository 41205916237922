import axios from "axios";
axios.defaults.withCredentials = true;

export const sendData = async (urlSend, formData, callback, credential, method = "POST", Content_type_text = true) => {
    try {
        let url = "https://api.carlosduarte.com.br/".concat(urlSend);
        const config = {
            headers: {
                'Content-Type': (Content_type_text ? 'text/html' : 'multipart/form-data')
            },
            withCredentials: credential
        };
        let token = localStorage.getItem('token');

        if(token){
            formData.append('token', token);
            //formData.append('user_id', '6')
            url += method === "GET" ? "/?token=" + token : "";
        }
        console.log(url);
        console.log(formData);
        const response = method === "POST" ? await axios.post(url, formData, config) :
            await axios.get(url, { withCredentials: credential });

        if (response.data && response.data.token) {
            localStorage.setItem('token', response.data.token);
        }
        callback(null, response.data);
    } catch (error) {
        callback(error, null);
    }
};
