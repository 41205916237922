import 'rsuite/Divider/styles/index.css';
import 'rsuite/Pagination/styles/index.css';
import 'rsuite/Loader/styles/index.css';
import 'rsuite/Placeholder/styles/index.css';
import "./filmes.css";
import React, { useState, useEffect } from 'react';
import Botao from 'app_componentes/Botao/Botao';
import { sendData } from 'app_utils/sendData';
import CardFilme from 'app_componentes/CardFilme/CardFilme';
import { Content, Divider, Pagination, Loader, Affix } from "rsuite";
import ModalCatalogs from './ModalCatalogs';

function Filmes() {
    const [film, setFilm] = useState('');
    const [filmsList, setFilmsList] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [disabled, setDisabled] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [title, setTitle] = useState("Filmes Populares");
    const [newAction, setAction] = useState('get_popular');
    const [loaging, setLoading] = useState(true);
    const [selectedMovie, setSelectedMovie] = useState(null);

    useEffect(() => {
        buscaFilmes(newAction, page);
    }, [newAction, page]);

    const buscaFilmes = async (action, pageToFetch = 1) => {
        setLoading(true);
        setFilmsList([]);
        setTotalPages(0);
        setDisabled(true);

        const filmsCallback = (error, data) => {
            if (!error && data) {
                setFilmsList(data.response_received.results);
                setTotalPages(data.response_received.total_pages);
            } else {
                setFilmsList([]);
                setTotalPages(0);
            }
            setDisabled(false);
            setLoading(false);
        };

        var formData = new FormData();
        if (action === 'search') {
            formData.append('film', film);
        }
        formData.append('action', action);
        formData.append('page', pageToFetch);

        sendData('film', formData, filmsCallback, true, "POST");
    };

    const handlePageChange = (newPage) => {
        setPage(newPage);
        buscaFilmes(newAction, newPage);
    };

    const handleFormSubmit = (event) => {
        event.preventDefault();
        setPage(1);
        if (film.trim().length === 0) {
            setAction('get_popular');
            setTitle('Filmes Populares');
        }
        else {
            setAction('search');
            setTitle('Filmes encontrados');
        }

        buscaFilmes(newAction, 1);
    };

    const handleInputChange = (event) => {
        setFilm(event.target.value);
    };

    const handleKeyPress = (event) => {
        if (film.trim().length === 0) {
            setAction('get_popular');
            setTitle('Filmes Populares');
        }
        else {
            setAction('search');
            setTitle('Filmes encontrados');
        }

        if (event.key === 'Enter') {
            handleFormSubmit(event);
        }
    };

    const handleOpenModal = (movie) => {
        setSelectedMovie(movie);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (
        <Content className="content_page">
            <div className="movie_page">
                <form onSubmit={handleFormSubmit} className="movie_page">
                    <input
                        type="text"
                        placeholder="Digite o nome do filme para procurar"
                        value={film}
                        onChange={handleInputChange}
                        name="film"
                    />
                    <Botao type="submit" disabled={disabled}>Buscar Filmes</Botao>
                </form>
            </div>
            <Affix top={80}>
                <div className='background_opacity'>
                    <Divider>{title}</Divider>
                    <div>
                        <Pagination
                            layout={['-', 'pager']}
                            size={'xs'}
                            prev={true}
                            next={true}
                            first={true}
                            last={true}
                            ellipsis={true}
                            boundaryLinks={true}
                            total={totalPages}
                            limit={20}
                            maxButtons={5}
                            activePage={page}
                            onChangePage={handlePageChange}
                            disabled={disabled}
                        />
                    </div>
                </div>
            </Affix>
            <div className="movie-list">
                {(filmsList.length === 0 && loaging) ? (
                    <Loader size="md" speed="fast" backdrop content="Carregando Lista..." vertical />
                ) : (
                    filmsList.map((movie, index) => (
                        <div key={index} onClick={() => handleOpenModal(movie)}>
                            <CardFilme
                                movie={movie}
                                adult={movie.adult}
                                coverImage={`https://image.tmdb.org/t/p/w500${movie.poster_path}`}
                                translatedName={movie.title}
                                originalName={movie.original_title}
                                synopsis={movie.overview}
                                releaseYear={(movie.release_date || '').split('-')[0]}
                            />
                        </div>
                    ))
                )}
            </div>
            <div>
                <hr />
                <Pagination
                    layout={['-', 'pager']}
                    size={'xs'}
                    prev={true}
                    next={true}
                    first={true}
                    last={true}
                    ellipsis={true}
                    boundaryLinks={true}
                    total={totalPages}
                    limit={20}
                    maxButtons={5}
                    activePage={page}
                    onChangePage={handlePageChange}
                    disabled={disabled}
                />
            </div>

            {/* Modal para selecionar catálogos */}
            <ModalCatalogs show={showModal} onHide={handleCloseModal} movie={selectedMovie}/>
        </Content>
    );
}

export default Filmes;
