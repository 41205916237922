import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from "./app_componentes/AuthContext/AuthContext";

import Login from './app_pages/Login';
import Desenvolvedores from 'app_pages/Desenvolvedores';
import Sobre from 'app_pages/Sobre';
import Ajuda from 'app_pages/Ajuda';
import Postagem from 'app_pages/Postagem/Postagem';
import Inicio from 'app_pages/Inicio';
import CorteImagem from 'app_pages/CorteImagem';
import MainFooter from 'app_fragmentos/MainFooter/MainFooter';
import withAuthCheck from 'withAuthCheck';
import PageFeed from 'app_pages/Feed';
import PageFilmes from 'app_pages/Filmes';
import MyCatalogs from 'app_pages/MyCatalogs';
import ProfilePage from 'app_pages/Perfil';
import MyCatalogDetails from 'app_pages/CatalogDetails'

function AppRoutes() {
    const protectedRoutes = [
        { path: '/about', component: Sobre },
        { path: '/cutimage', component: CorteImagem },
        { path: '/post', component: Postagem },
        { path: '/feed', component: PageFeed },
        { path: '/', component: PageFeed },
        { path: '/films', component: PageFilmes },
        { path: '/catalog', component: MyCatalogs },
        { path: '/profile/:userId', component: ProfilePage },
        { path: "/catalog/:catalogId", component: MyCatalogDetails},
    ];

    return (
        <AuthProvider>
            <div className='rs-theme-dark'>
                <Router>
                    <Routes>
                        <Route path='/help' element={<Ajuda />} />
                        <Route path='/developers' element={<Desenvolvedores />} />
                        <Route path='/login' element={<Inicio><MainFooter><Login /></MainFooter></Inicio>} />
                        <Route path='/avatar' element={<CorteImagem />} />

                        /* Rotas Protegidas */
                        {protectedRoutes.map(({ path, component }) => {
                            const ProtectedComponent = withAuthCheck(component);
                            return <Route key={path} path={path} element={<ProtectedComponent />} />;
                        })}
                    </Routes>
                </Router>
            </div>
        </AuthProvider>
    );
};

export default AppRoutes;