import React from 'react';
import PropTypes from 'prop-types';
import './CardFeed.css';
import ComentarioPost from 'app_componentes/ComentariosPost/ComentariosPost';
import { useAuth } from 'app_componentes/AuthContext/AuthContext';
import CardAnswer from './CardAnswer';
import { timeSince } from 'app_utils/timeSince';
import YouTubeVideo from 'app_componentes/YouTubeVideo/YouTubeVideo';
import { useNavigate } from 'react-router-dom';

function CardFeed({ avatar, username, postTime, message, postImage, video, answers, userId }) {
    const navigate = useNavigate();
    const { isAuthenticated, user } = useAuth();

    const openProfile = () => {
        navigate(`/profile/${userId}`);
    };

    return (
        <div className="social-feed-card">
            <div className="social-feed-card__header">
                <img src={avatar} alt="User avatar" className="social-feed-card__avatar" onClick={openProfile} />
                <div className="social-feed-card__info" onClick={openProfile}>
                    <span className="social-feed-card__username">{username}</span>
                    <span className="social-feed-card__time">{postTime}</span>
                </div>
            </div>
            <div className="social-feed-card__body">
                <p className="social-feed-card__message">{message}</p>
                {postImage && <img src={postImage} alt="Post" className="social-feed-card__image" />}
                {video && <div><YouTubeVideo videoId={video} /></div>}
            </div>
            <div><hr/></div>
            <div>{answers ? (answers.map((answer) => (
                <CardAnswer
                    key={answer.post_id}
                    nickname={answer.user_nickname}
                    avatar={answer.user_avatar}
                    postTime={timeSince(answer.message_created_at)}
                    message={answer.content} />
            ))) : ""}</div>
        </div>
    );
};

CardFeed.propTypes = {
    avatar: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
    postTime: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    postImage: PropTypes.string,
};

export default CardFeed;