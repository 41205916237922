import Amigos from "app_componentes/Amigos/Amigos";
import MenuLateral from "app_componentes/MenuLateral/MenuLateral";
import ContainerTriplo from "app_fragmentos/ContainerTriplo/ContainerTriplo";
import MenuFooter from "app_fragmentos/MenuFooter/MenuFooter";
import Inicio from "app_pages/Inicio";

function Sobre() {

    return (
        <Inicio>
            <ContainerTriplo
                childrenE={<MenuFooter><MenuLateral /></MenuFooter>}
                childrenC={
                    <div>
                        <h2>Trabalho de Programação Web</h2>
                        <p>Este projeto consiste em um trabalho desenvolvido na disciplina de Programação para WEB (INE-5646), lecionada pelo professor Camilo.</p>

                        <h3>Descrição</h3>
                        <p>O objetivo desse projeto é construir um site que permita aos usuários consumir informações de filmes através da TMDB API, favoritar filmes e visualizar perfis de outros usuários e seus filmes favoritos. Além disso, o site exibirá uma série de informações das obras, incluindo detalhes como título, ano de lançamento, gênero e avaliações.</p>

                        <h3>Tecnologias Utilizadas</h3>

                        <h4>API Utilizada</h4>
                        <ul>
                            <li><strong>TMDB API</strong>: Usamos a TMDB API para buscar informações detalhadas sobre filmes. Essa API fornece dados como sinopse, atores, avaliações e muito mais.</li>
                        </ul>

                        <h4>Outras Tecnologias</h4>
                        <ul>
                            <li><strong>Frontend:</strong> Utilizamos o React para construir a interface do usuário de forma eficiente e responsiva.</li>
                            <li><strong>Backend:</strong> O PHP é utilizado para gerenciar a lógica do servidor e manipulação de dados.</li>
                            <li><strong>SGBD:</strong> O MySQL é o sistema de gerenciamento de banco de dados escolhido para armazenar as informações do site.</li>
                        </ul>

                        <h3>Funcionalidades</h3>

                        <h4>Consumo de API de Filmes</h4>
                        <p>O site utiliza a TMDB API para fornecer aos usuários informações completas e atualizadas sobre uma vasta gama de filmes. Os usuários podem visualizar detalhes como sinopse, elenco e avaliações.</p>

                        <h4>Sistema de Favoritos</h4>
                        <p>Os usuários podem favoritar filmes para acessá-los rapidamente em visitas futuras. Essa funcionalidade permite aos usuários criar uma lista personalizada de seus filmes preferidos.</p>

                        <h4>Perfil de Usuário</h4>
                        <p>Cada usuário pode criar seu perfil no site, permitindo-lhes não só gerenciar seus filmes favoritos, mas também permitir que outros usuários vejam quais filmes eles gostaram.</p>
                    </div>
                }
                childrenD={<Amigos />} />
        </Inicio>
    );
}

export default Sobre;