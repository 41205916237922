import React, { useState, useEffect } from 'react';
import './CadastroPost.css';
import { sendData } from 'app_utils/sendData';

const CadastroPost = ({ user, setPosts }) => {
  const [comment, setComment] = useState('');
  const [mediaType, setMediaType] = useState('none');
  const [image, setImage] = useState(null);
  const [videoLink, setVideoLink] = useState('');
  const [isValidLink, setIsValidLink] = useState(true);

  const regex = /(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
  const handleCommentChange = (e) => setComment(e.target.value);
  const handleVideo = () => setMediaType('video');
  const handleImage = () => setMediaType('image');
  const handleNone = () => setMediaType('none');
  const handleImageChange = (e) => setImage(e.target.files[0]);
  const handleVideoLinkChange = (e) => {
    setIsValidLink(regex.test(e.target.value));
    isValidLink ? setVideoLink(e.target.value) : setVideoLink("");
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    let content_type = true;
    var formData = new FormData();
    formData.append('post_comment', comment);
    formData.append('post_answer', '');

    if (mediaType === 'video') {
      formData.append('post_video', videoLink);
    } else if (mediaType === 'image') {
      formData.append('post_image', image);
      content_type = false;
    }

    const postCallBack = (error, data) => {
      console.log(error);
      console.log(data);
      if (!error && data.response_received) {
        window.location.reload();
      }
    };

    sendData('post', formData, postCallBack, true, 'POST', content_type);
  };

  useEffect(() => {
    return () => {
      <div>Enviando o post...</div>
    };
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <div className="cadastro_post_container">
          <img src={user.avatar} alt='Avatar do usuário' className='social-feed-card__avatar' />
          <input
            type="text"
            value={comment}
            onChange={handleCommentChange}
            placeholder="O que você está pensando?"
          />
            <button type="button" className="emoji_button" onClick={handleVideo}>
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-camera-video" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M0 5a2 2 0 0 1 2-2h7.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 4.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 13H2a2 2 0 0 1-2-2zm11.5 5.175 3.5 1.556V4.269l-3.5 1.556zM2 4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h7.5a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1z"/>
              </svg>
            </button>
            <button type="button" className="emoji_button" onClick={handleImage}>
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-image" viewBox="0 0 16 16">
              <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0"/>
              <path d="M2.002 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2zm12 1a1 1 0 0 1 1 1v6.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12V3a1 1 0 0 1 1-1z"/>
            </svg>
            </button>
            <button type="button" className="emoji_button" onClick={handleNone}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" width="24" height="24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"/>
              </svg>
            </button>
        </div>
      </div>
      {mediaType === 'image' && (
        <div className="campo-texto">

          <div className="file-upload">
            <input type="file" accept=".jpg, .jpeg, .png" onChange={handleImageChange} />
            <div className="file-upload-button">
              Selecione uma imagem
            </div>
          </div>
        </div>
      )}
      {mediaType === 'video' && (
        <div className="campo-texto">
          <label>{isValidLink ? "Vídeo YouTube: " : "Link inválido do YouTube: "}</label>
          <input
            type="url"
            value={videoLink}
            onChange={handleVideoLinkChange}
            placeholder="Digite o link do vídeo do YouTube"
          />
        </div>
      )}
      <div className='div_button'>
        <button type="submit">Postar</button>
      </div>
    </form>
  );
};

export default CadastroPost;
