import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Inicio from 'app_pages/Inicio';
import ContainerTriplo from 'app_fragmentos/ContainerTriplo/ContainerTriplo';
import MenuFooter from 'app_fragmentos/MenuFooter/MenuFooter';
import Profile from 'app_componentes/Perfil/Perfil';
import Amigos from 'app_componentes/Amigos/Amigos';
import MenuLateral from 'app_componentes/MenuLateral/MenuLateral';

function ProfilePage() {
  const { userId } = useParams();
  let currentUser = JSON.parse(localStorage.getItem('user'));
  console.log(JSON.parse(localStorage.getItem('user')))
  console.log(userId)
  const [isCurrentUser, setCurrentUser] = useState(currentUser.id == userId);

  useEffect(() => {
    setCurrentUser(currentUser.id == userId);
  }, [userId, currentUser.id]);

  return (
    <Inicio>
      <ContainerTriplo
        childrenE={<MenuFooter><MenuLateral /></MenuFooter>}
        childrenC={<Profile userId={userId} isCurrentUser={isCurrentUser} />}
        childrenD={<Amigos />}
      />
    </Inicio>
  );
}

export default ProfilePage;
