import { useNavigate } from "react-router-dom";
import { useAuth } from 'app_componentes/AuthContext/AuthContext';
import { sendData } from "app_utils/sendData";

export const useLogout = () => {
  const { setIsAuthenticated, setUser } = useAuth();
  const navigate = useNavigate();

  const logout = () => {
    localStorage.clear();
    setIsAuthenticated(false);
    setUser("");

    const logoutCallback = (error, data) => {
      if (!error && !data.response_received.authenticated) {
        console.log("Deslogado");
        navigate("/login");
      } else {
        console.log("Não Deslogado");
      }
    };

    sendData("logout", new FormData(), logoutCallback, true, "POST");
  };

  return logout;
};
