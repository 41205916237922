import 'rsuite/Input/styles/index.css';
import 'rsuite/InputGroup/styles/index.css';
import { sendData } from 'app_utils/sendData';
import React, { useState, useEffect } from 'react';
import { Modal, CheckboxGroup, Checkbox, Form } from 'rsuite';

const ModalCatalogs = ({ show, onHide, movie }) => {
    const [catalogs, setCatalogs] = useState([]);
    const [selectedCatalogs, setSelectedCatalogs] = useState([]);
    const [newCatalog, setNewCatalog] = useState('');

    useEffect(() => {
        if (show) {
            fetchCatalogs();
        }
    }, [show]);

    const fetchCatalogs = async () => {
        const catalogsCallback = (error, data) => {
            if (!error && data.response_received) {
                setCatalogs(data.response_received);
                setSelectedCatalogs([]);
            }
        };

        let formData = new FormData();
        formData.append('action', 'get_catalogs');

        sendData('catalog', formData, catalogsCallback, true, "POST");
    };

    const handleSelectCatalog = (values) => {
        setSelectedCatalogs(values);
    };

    const handleAddNewCatalog = () => {
        if (newCatalog.trim()) {
            const newCatalogObj = { catalog_id: 0, catalog_name: newCatalog };
            setCatalogs([...catalogs, newCatalogObj]);
            setNewCatalog('');
        }
    };

    const handleSave = () => {
        if (selectedCatalogs.length > 0) {
            const catalogsCallback = (error, data) => {
                if (!error)
                    console.log('Catálogos selecionados:', selectedCatalogs);
            };

            let formData = new FormData();
            formData.append('action', 'add_movie_to_catalog');
            formData.append('movie', JSON.stringify(movie));

            console.log(movie);

            formData.append('catalogs', JSON.stringify(selectedCatalogs));

            sendData('catalog', formData, catalogsCallback, true, "POST");
        }
        onHide();
    };

    const styles = {
        width: 300,
        marginBottom: 10
    };

    return (
        <Modal open={show} onClose={onHide}>
            <Modal.Header>
                <Modal.Title>Selecionar Catálogos</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form fluid>
                    <div className="custom-checkbox-group">
                        <label>Catálogos Disponíveis</label>
                        <CheckboxGroup inline onChange={handleSelectCatalog} value={selectedCatalogs}>
                            {catalogs.map((catalog, index) => (
                                <Checkbox key={index} value={catalog.catalog_id + '-' + catalog.catalog_name}>{catalog.catalog_name}</Checkbox>
                            ))}
                        </CheckboxGroup>
                    </div>
                    <div>
                        <label>Adicionar Novo Catálogo</label>
                        <input
                            type="text"
                            id="input-new-catalog"
                            value={newCatalog}
                            onChange={(e) => setNewCatalog(e.target.value)}
                            className="custom-input"
                        />
                        <button className="custom-button add" onClick={handleAddNewCatalog}>Adicionar</button>
                    </div>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <button className="custom-button save" onClick={handleSave} appearance="primary">Salvar</button>
                <button className="custom-button cancel" onClick={onHide} appearance="subtle">Cancelar</button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalCatalogs;