import './Footer.css';
import imgLogo from "resources/img/logo-cineine.png";

function Footer() {
    return (
        <>
            <div className="footer">
                <div>
                    <img src={imgLogo} alt="logo" />
                    © 2024 CiINE.
                </div>
            </div>
        </>
    )
}

export default Footer