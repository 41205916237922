import Amigos from "app_componentes/Amigos/Amigos";
import MenuLateral from "app_componentes/MenuLateral/MenuLateral";
import ContainerTriplo from "app_fragmentos/ContainerTriplo/ContainerTriplo";
import MenuFooter from "app_fragmentos/MenuFooter/MenuFooter";
import Inicio from "app_pages/Inicio";
import Catalogos from "app_componentes/Catalogos/Catalogos";

const MyCatalogs = () => {
    return (
        <Inicio>
            <ContainerTriplo
                childrenE={<MenuFooter><MenuLateral /></MenuFooter>}
                childrenC={<Catalogos />}
                childrenD={<Amigos />} />
        </Inicio>
    );
}

export default MyCatalogs;