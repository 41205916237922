import Amigos from "app_componentes/Amigos/Amigos";
import MenuLateral from "app_componentes/MenuLateral/MenuLateral";
import ContainerTriplo from "app_fragmentos/ContainerTriplo/ContainerTriplo";
import MenuFooter from "app_fragmentos/MenuFooter/MenuFooter";
import Inicio from "app_pages/Inicio";
import CatalogoDetails from "app_componentes/Catalogos/CatalogoDetails";

const MyCatalogsDetails = () => {
    return (
        <Inicio>
            <ContainerTriplo
                childrenE={<MenuFooter><MenuLateral /></MenuFooter>}
                childrenC={<CatalogoDetails />}
                childrenD={<Amigos />} />
        </Inicio>
    );
}

export default MyCatalogsDetails;