import './AppHeader.css';
import React from 'react';
import logo from "resources/img/logo-cineine.png";
import { Link } from 'react-router-dom';
import { Input, Button } from 'rsuite';

import DropdownMenu from './DropdownMenu';

function AppHeader() {
    const isAuthenticated = localStorage.getItem('authenticated');
    const user = JSON.parse(localStorage.getItem('user'));

    return (
        <header className='Header'>
            <div className='Div-Logo'>
                <Link to="/">
                    <img src={logo} className='Header-logo' alt='logo' />
                </Link>
            </div>
            <div className='Header-right'>
                {isAuthenticated ? (
                    <>
                        <Link to="/catalog">
                            <Button className='Header-catalog-button'>Meus Catálogos</Button>
                        </Link>
                        <DropdownMenu user={user} />
                    </>
                ) : (
                    <Link to="/login">Login</Link>
                )}
            </div>
        </header>
    );
}

export default AppHeader;