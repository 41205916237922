import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from "app_componentes/AuthContext/AuthContext";
import { sendData } from 'app_utils/sendData';

const AuthCheck = ({ children }) => {
    const { isAuthenticated, setIsAuthenticated, setUser } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const checkAuth = (error, data) => {

            if (!error && data.response_received.authenticated) {
                    setIsAuthenticated(true);
                    console.log("Usuário logado");
            } else {
                console.error(error);
                setIsAuthenticated(false);
                console.log("Usuário não logado");
                navigate('/login');
            }
        };

        if (location.pathname !== '/login') {
            sendData("check_user", new FormData(), checkAuth, true, "POST");
        }

    }, [location.pathname, setIsAuthenticated, navigate, setUser]);

    return isAuthenticated ? children : null;
};

export default AuthCheck;
