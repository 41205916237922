import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import './MenuLateral.css';

function MenuLateral() {
  const menuLateralRef = useRef(null);
  const handleScroll = (event) => {
    if (menuLateralRef.current) {
      menuLateralRef.current.scrollTop += event.deltaY;
    }
  };

  return (
    <div className="menu-lateral"
      ref={menuLateralRef}
      onWheel={handleScroll} >
      <h2>Menu Lateral</h2>

      <h3>Geral</h3>
      <Link to="/about">Sobre</Link><br />
      <Link to="/developers">Desenvolvedores</Link>

      <h3>Navegação</h3>
      <Link to="/feed" >Feed</Link><br />
      <Link to="/films" >Filmes</Link><br />
      <Link to="/catalog" >Meus Catálogos</Link><br />

    </div>
  );
}

export default MenuLateral;
