import 'rsuite/Avatar/styles/index.css';
import React, { useRef, useEffect, useState } from 'react';
import './Amigos.css';
import { sendData } from 'app_utils/sendData';
import { Avatar } from 'rsuite';
import CheckIcon from '@rsuite/icons/Check';
import CloseIcon from '@rsuite/icons/Close';

function Amigos() {
  const amigosRef = useRef(null);
  const [friends, setFriends] = useState([]);
  const [pendingRequests, setPendingRequests] = useState([]);

  const handleScroll = (event) => {
    if (amigosRef.current) {
      amigosRef.current.scrollTop += event.deltaY;
    }
  };

  const handleFriendAction = (friendId, action) => {
    let formData = new FormData();
    formData.append('action', action);
    formData.append('friend', friendId);

    sendData('friends', formData, (error, data) => {
      if (!error && data.success) {
        fetchPendingRequests();
        fetchFriendsList();
      }
    }, true, 'POST', true);
  };

  const fetchFriendsList = () => {
    let formData = new FormData();
    formData.append('action', 'get');

    sendData('friends', formData, (error, data) => {
      if (!error && Array.isArray(data.response_received)) {
        const approvedFriends = data.response_received.filter(friend => friend.approved);
        setFriends(approvedFriends);
      }
    }, true, 'POST');
  };

  const fetchPendingRequests = () => {
    let formData = new FormData();
    formData.append('action', 'in_approve');

    sendData('friends', formData, (error, data) => {
      if (!error && Array.isArray(data.response_received)) {
        setPendingRequests(data.response_received);
      }
    }, true, 'POST');
  };

  useEffect(() => {
    fetchFriendsList();
    fetchPendingRequests();
  }, []);

  return (
    <div className="amigos" ref={amigosRef} onWheel={handleScroll}>
      {pendingRequests.length > 0 && (
        <div className="pending-requests">
          <h3>Solicitações de Amizade</h3>
          {pendingRequests.map((request) => (
            <div key={request.friend_id} className="friend">
              <div className="friend-info">
                <div className="friend-nickname">{request.nickname}</div>
                <div className="friend-actions">
                  <CheckIcon className="action-icon" onClick={() => handleFriendAction(request.friend_id, 'approve')} />
                  <CloseIcon className="action-icon" onClick={() => handleFriendAction(request.friend_id, 'disapprove')} />
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      <h2>Amigos</h2>
      <div className="friend-list">
        {friends.map((friend) => (
          <div key={friend.friend_id} className="friend">
            {friend.avatar ? (
              <img src={friend.avatar} alt={`${friend.nickname}'s avatar`} className="friend-avatar" />
            ) : (
              <Avatar className="friend-avatar" circle style={{ background: '#000' }}>
                {friend.nickname.toUpperCase().charAt(0)}
              </Avatar>
            )}
            <div className="friend-info">
              <div className="friend-nickname">{friend.nickname}</div>
              <div className="friend-last-login">Último login: {new Date(friend.last_accessed_at).toLocaleDateString()}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Amigos;