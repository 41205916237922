import React, { useState, useEffect } from 'react';
import './Perfil.css';
import { sendData } from 'app_utils/sendData';
import CardFeed from 'app_componentes/CardFeed/CardFeed';
import { timeSince } from "app_utils/timeSince";

const Profile = ({ userId, isCurrentUser }) => {
  const [user, setUser] = useState(null);
  const [posts, setPosts] = useState([]);
  const [friendStatus, setFriendStatus] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const userCallback = (error, data) => {
      if (!error && data.response_received) {
        setUser(data.response_received.profile);
        setPosts(data.response_received.posts);
        setFriendStatus(data.response_received.friend_status);
      } else {
        setError('Failed to fetch user data.');
      }
    };

    let formData = new FormData();
    formData.append('action', 'get');
    formData.append('profile_id', userId);

    sendData('profile', formData, userCallback, true, 'POST', true);
  }, [userId]);

  const handleAddFriend = () => {
    let formData = new FormData();
    formData.append('action', 'request');
    formData.append('friend', userId);

    sendData('friends', formData, (error, data) => {
      if (!error && data.response_received.success) {
        setFriendStatus('pending');
      } else {
        setError('Failed to send friend request.');
      }
    }, true, 'POST', true);
  };

  const handleRemoveFriend = () => {
    let formData = new FormData();
    formData.append('action', 'delete');
    formData.append('friend', userId);

    sendData('friends', formData, (error, data) => {
      if (!error && data.response_received.success) {
        setFriendStatus('not_added');
      } else {
        setError('Failed to remove friend.');
      }
    }, true, 'POST', true);
  };

  const [isEditing, setIsEditing] = useState(false);
  
  const [name, setName] = useState('');
  const [lastname, setLastname] = useState('');
  const [nickname, setNickname] = useState('');
  const [email, setEmail] = useState('');
  const [avatar, setAvatar] = useState('');

  const [editName, setEditName] = useState('');
  const [editLastname, setEditLastname] = useState('');
  const [editNickname, setEditNickname] = useState('');
  const [editEmail, setEditEmail] = useState('');
  const [editAvatar, setEditAvatar] = useState('');

  useEffect(() => {
    if (user) {
      setName(user.name);
      setLastname(user.lastname);
      setNickname(user.nickname);
      setEmail(user.email);
      setAvatar(user.avatar);
      setEditName(user.name);
      setEditLastname(user.lastname);
      setEditNickname(user.nickname);
      setEditEmail(user.email);
      setEditAvatar(user.avatar);
    }
  }, [user]);

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
    setEditName(name);
    setEditLastname(lastname);
    setEditNickname(nickname);
    setEditEmail(email);
    setEditAvatar(avatar);
  };

  const handleNameChange = (e) => setEditName(e.target.value);
  const handleLastnameChange = (e) => setEditLastname(e.target.value);
  const handleNicknameChange = (e) => setEditNickname(e.target.value);
  const handleEmailChange = (e) => setEditEmail(e.target.value);

  const handleProfilePictureChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setEditAvatar(event.target.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleSave = () => {
    let formData = new FormData();
    formData.append('action', 'update');
    formData.append('name', editName);
    formData.append('lastname', editLastname);
    formData.append('nickname', editNickname);
    formData.append('email', editEmail);
    
    sendData('profile_user', formData, (error, data) => {
      if (!error && data.response_received.success) {
        setName(editName);
        setLastname(editLastname);
        setNickname(editNickname);
        setEmail(editEmail);
        setAvatar(editAvatar);
        setUser({ ...user, name: editName, lastname: editLastname, nickname: editNickname, email: editEmail });
        console.log('Profile updated successfully');
      } else {
        setError('Failed to update profile.');
      }
    }, true, 'POST', true);

    if (editAvatar !== avatar) {
      let avatarFormData = new FormData();
      avatarFormData.append('action', 'update_avatar');
      avatarFormData.append('avatar', editAvatar);
      sendData('profile_user', avatarFormData, (error, data) => {
        if (!error && data.success) {
          setAvatar(editAvatar);
        } else {
          setError('Failed to update avatar.');
        }
      }, true, 'POST', true);
    }

    setIsEditing(false);
  };

  if (error) {
    return <div>{error}</div>;
  }

  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <div className="profile">
      <div className="profile-header">
        <img src={avatar} alt={name} className="profile-avatar" />
        <div className={`profile-info ${isEditing ? 'edit-mode' : ''}`}>
          {isEditing ? (
            <>
              <input type="text" value={editName} onChange={handleNameChange} placeholder="Name" />
              <input type="text" value={editLastname} onChange={handleLastnameChange} placeholder="Lastname" />
              <input type="text" value={editNickname} onChange={handleNicknameChange} placeholder="Nickname" />
              <input type="email" value={editEmail} onChange={handleEmailChange} placeholder="Email" />
              <input type="file" onChange={handleProfilePictureChange} />
              <div className="button-group">
                <button onClick={handleSave} className="save-button">Salvar</button>
                <button onClick={handleEditToggle} className="cancel-button">Cancelar</button>
              </div>
            </>
          ) : (
            <>
              <h2>{name} {lastname}</h2>
              <p>{nickname}</p>
              <p>{email}</p>
              {isCurrentUser ? (
                <button onClick={handleEditToggle} className="edit-button">Editar Perfil</button>
              ) : (
                <>
                  {friendStatus === null ? (
                    <div>Loading friend status...</div>
                  ) : friendStatus === 'added' ? (
                    <button onClick={handleRemoveFriend} className="remove-friend-button">Remover Amigo</button>
                  ) : friendStatus === 'not_added' ? (
                    <button onClick={handleAddFriend} className="add-friend-button">Adicionar Amigo</button>
                  ) : (
                    <button disabled className="pending-button">Solicitação Pendente</button>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
      <div className="profile-posts">
        <h3>Posts</h3>
        {posts.length > 0 ? (
          posts.map(post => (
            <CardFeed
              key={post.post_id}
              avatar={avatar}
              username={nickname}
              postTime={timeSince(post.message_created_at)}
              message={post.content}
              postImage={post.post_image}
              video={post.video}
              answers={post.answers}
              userId={user.user_id}
            />
          ))
        ) : (
          <p>No posts available.</p>
        )}
      </div>
    </div>
  );
};

export default Profile;