import 'rsuite/Modal/styles/index.css';
import './LoginForm.css';
import React, { useState } from 'react';
import Botao from '../Botao/Botao';
import { validaEmail } from "../../app_utils/validaEmail"
import { useAuth } from 'app_componentes/AuthContext/AuthContext';
import { sendData } from 'app_utils/sendData';
import { Modal, Button } from 'rsuite';
import CriarConta from 'app_pages/CriarConta';
import { useNavigate } from 'react-router-dom';

function LoginForm() {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const { setIsAuthenticated, setUser } = useAuth();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const navigate = useNavigate();

    const handleLogin = async (event) => {
        event.preventDefault();
        setIsButtonDisabled(true);

        const loginCallback = (error, data) => {
            if (!error && data.response_received.authenticated) {
                setUser(data);
                setIsAuthenticated(true);
                localStorage.setItem('authenticated', 1);
                localStorage.setItem('user', JSON.stringify(data.user));
                navigate('/feed');
            } else {
                console.log("Usuário não logado");
                setIsButtonDisabled(false)
            }
        };

        let loginData = new FormData();
        loginData.append('email', email);
        loginData.append('password', password);

        sendData("login", loginData, loginCallback, true, "POST");
    };

    const handleEmailBlur = () => {
        if (validaEmail(email)) {
            setIsButtonDisabled(false);
        }
        else {
            setIsButtonDisabled(true);
        }
    };

    return (
        <>
            <section className='divForm'>
                <form onSubmit={handleLogin}>
                    <div className='loginEmailLabel'>
                        <label>Email: </label>
                    </div>
                    <input label="E-mail"
                        id="loginEmailInput"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        onBlur={handleEmailBlur}
                        placeholder={"Digite seu endereço de e-mail"} />

                    <div className='loginPasslLabel'>
                        <label>Senha: </label>
                    </div>
                    <input
                        id="loginPassInput"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder={"Digite sua senha"} />

                    <Botao id="loginButton" type="submit" disabled={isButtonDisabled}>Entrar</Botao><br />
                </form>
            </section>
            <div className='margem-top'>
                <p>
                    <Botao id="loginCreateAccount" onClick={handleOpen} disabled={false}>Criar nova conta</Botao>
                </p>
            </div>

            <Modal backdrop="static" keyboard={true} open={open} onClose={handleClose}>
                <Modal.Header/>
                <Modal.Body>
                    <CriarConta />
                </Modal.Body>
            </Modal>
        </>
    );
};

export default LoginForm;