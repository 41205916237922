import { Content, Footer } from "rsuite";
import MeuFooter from "app_componentes/Footer/Footer";
import "./MainFooter.css";

function MainFooter({ children }) {
    return (
        <Content className="MainFooter">
            {children}
            <Footer>
                <MeuFooter />
            </Footer>
        </Content>
    );
}

export default MainFooter;