import MeuFooter from 'app_componentes/Footer/Footer';
import { Footer } from 'rsuite';

function MenuFooter({children}) {
    return (
        <div className='fixed' >
            <div>
                {children}
            </div>
            <div>
                <Footer>
                    <MeuFooter />
                </Footer>
            </div>
        </div>
    );

}

export default MenuFooter;