import React, { useState, useEffect } from 'react';
import './Catalogos.css';
import { sendData } from 'app_utils/sendData';
import CatalogCard from './CardCatalogo';
import { useNavigate } from 'react-router-dom';

const MyCatalogs = () => {
  const [catalogs, setCatalogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showAddCatalog, setShowAddCatalog] = useState(false);
  const [newCatalog, setNewCatalog] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    fetchCatalogs();
  }, []);

  const fetchCatalogs = () => {
    let formData = new FormData();
    formData.append('action', 'get_catalogs');

    sendData('catalog', formData, (error, data) => {
      if (!error && data.response_received) {
        setCatalogs(data.response_received);
      } else {
        setError('Failed to fetch catalogs.');
      }
      setLoading(false);
    }, true, 'POST', true);
  };

  const handleAddCatalog = () => {
    if (newCatalog.trim()) {
      let formData = new FormData();
      formData.append('action', 'create');
      formData.append('catalog_name', newCatalog);
      formData.append('description', '')

      sendData('catalog', formData, (error, data) => {
        if (!error && data.response_received) {
          fetchCatalogs();
        } else {
          setError('Failed to add catalog.');
        }
        setNewCatalog('');
        setShowAddCatalog(false);
      }, true, 'POST');
    }
  };

  if (error) {
    return <div>{error}</div>;
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="catalogs">
      <h2>Meus Catálogos</h2>
      <div className="add-catalog-container">
        <button onClick={() => setShowAddCatalog(true)} className="custom-button add">Adicionar Catálogo</button>
        {showAddCatalog && (
          <div className="add-catalog-form">
            <input
              type="text"
              value={newCatalog}
              onChange={(e) => setNewCatalog(e.target.value)}
              placeholder="Nome do Catálogo"
            />
            <button onClick={handleAddCatalog} className="custom-button save">Salvar</button>
          </div>
        )}
      </div>
      <div className="catalog-list">
        {catalogs.map(catalog => (
          <CatalogCard
            key={catalog.catalog_id}
            catalog={catalog}
            onOpenCatalog={() => navigate(`/catalog/${catalog.catalog_id}`)}
          />
        ))}
      </div>
    </div>
  );
};

export default MyCatalogs;