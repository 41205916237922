import Amigos from "app_componentes/Amigos/Amigos";
import Feed from "app_componentes/Feed/Feed";
import MenuLateral from "app_componentes/MenuLateral/MenuLateral";
import ContainerTriplo from "app_fragmentos/ContainerTriplo/ContainerTriplo";
import MenuFooter from "app_fragmentos/MenuFooter/MenuFooter";
import Inicio from "app_pages/Inicio";

function PageFeed() {
    const isAuthenticated = localStorage.getItem('authenticated') === '1';
    const user = JSON.parse(localStorage.getItem('user'));

    return (
        <Inicio>
            <ContainerTriplo
                childrenE={<MenuFooter><MenuLateral /></MenuFooter>}
                childrenC={<Feed user={user} isAuthenticated={isAuthenticated} />}
                childrenD={<Amigos />} />
        </Inicio>

    );
}

export default PageFeed;