
const CardAnswer = ({nickname, avatar, postTime, message}) => {
    return (
        <>
            <div className="social-feed-card__header">
                <img src={avatar} alt="User avatar" className="social-feed-card__avatar" />
                <div className="social-feed-card__info">
                    <span className="social-feed-card__username">{nickname}</span>
                    <span className="social-feed-card__time">{postTime}</span>
                </div>
            </div>
            <div className="social-feed-card__body">
                <p className="social-feed-card__message">{message}</p>
            </div>
        </>
    );
}


export default CardAnswer;