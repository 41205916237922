import AppHeader from 'app_componentes/AppHeader/AppHeader';
import { Container, Header } from "rsuite";
import "./Inicio.css";

function Inicio({ children }) {
    return (
        <div className="show-container">
            <Container>
                <Header className="AppHeader">
                    <AppHeader />
                </Header>
                <main className='main'>
                    <Container className='AppContent border'>
                        {children}
                    </Container>
                </main>
            </Container>
        </div >
    );
}

export default Inicio;